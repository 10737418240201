import { useEffect } from 'react';
import router from 'next/router';
import { NextPage } from 'next';

import { useUserContext } from 'provider';
import { REDIRECT_PATH } from 'common';

const Index : NextPage = () => {
  const { userInfo } = useUserContext();

  useEffect(() => {
    if (userInfo === undefined) return;
    router.replace(REDIRECT_PATH);
  }, [userInfo]);
  return null;
};

export default Index;
